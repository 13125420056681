import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import "../../css/global.css";
import apiStrapi from "../../utils/api/apiStrapi";
const initialState = {};

const options = {
  // // headerStyle: { backgroundColor: '#253053', color: 'white' },

  filtering: false,
  grouping: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 1000,
  paging: true,
  pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
  emptyRowsWhenPaging: false,
  search: true,
  sorting: false,
  toolbar: true,
  padding: "dense",
};

export default function StockReport() {
  const [form, setForm] = useState({ ...initialState });
  const [data, setData] = useState([]);
  const [entries, setEntries] = useState([]);
  const [exits, setExits] = useState([]);
  const [returns, setReturns] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const loadEntries = async () => {
    try {
      const result = await apiStrapi.get("items-of-entries?_limit=-1");
      const data = result.data;
      const dataEmtry = await apiStrapi.get("entries?_limit=-1");
      const materialTools = await apiStrapi.get("material-tools?_limit=-1");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const entry =
                dataEmtry.data.length &&
                dataEmtry.data.filter((e) => e.id == item.entrieId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (entry.length > 0 && materiaT.length > 0) {
                const model = {
                  id: entry[0].id,
                  date: entry[0].date,
                  orderOfServiceId: {
                    id: entry[0].orderOfServiceId.id,
                    nameOs: entry[0].orderOfServiceId.nameOs,
                    numberOs: entry[0].orderOfServiceId.numberOs,
                    centerOfCost: entry[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      entry[0].orderOfServiceId.durationOs != undefined
                        ? entry[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(entry[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: entry[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: entry[0].image,
                  MTCode: i.code,
                  clientId: entry[0].orderOfServiceId.clientId,
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        setEntries(newData);
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadReturns = async () => {
    try {
      const result = await apiStrapi.get("returns?_limit=-1");
      const data = result.data;
      if (data) setReturns(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadExits = async () => {
    try {
      const result = await apiStrapi.get("items-of-exits?_limit=-1");
      const dataExits = await apiStrapi.get("exits?_limit=-1");
      const data = result.data;
      const materialTools = await apiStrapi.get("material-tools?_limit=-1");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const exit =
                dataExits.data.length &&
                dataExits.data.filter((e) => e.id == item.exitId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (exit.length > 0 && materiaT.length > 0) {
                const model = {
                  id: exit[0].id,
                  date: exit[0].date,
                  orderOfServiceId: {
                    id: exit[0].orderOfServiceId.id,
                    nameOs: exit[0].orderOfServiceId.nameOs,
                    numberOs: exit[0].orderOfServiceId.numberOs,
                    centerOfCost: exit[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      exit[0].orderOfServiceId.durationOs != undefined
                        ? exit[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(exit[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: exit[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: exit[0].image,
                  MTCode: i.code,
                  clientId: exit[0].orderOfServiceId.clientId,
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        setExits(newData);
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const categoryName = item.category != null ? item.category.name : null;
        const category = item.category != null ? item.category.id : null;
        item.categoryName = categoryName;
        item.category = category;
        const UnitName = item.unitId != null ? item.unitId.name : null;
        const unitId = item.unitId != null ? item.unitId.id : null;
        item.UnitName = UnitName;
        item.unitId = unitId;
      });
      if (data.length) {
        console.log(`data`, data);
        setData(data);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const newData =
    data.length &&
    data.forEach((element) => {
      let amountEntry = 0;
      let amountReturn = 0;
      let amountExit = 0;
      entries &&
        entries.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountEntry = amountEntry + parseInt(item.amount, 10);
        });
      returns &&
        returns.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountReturn = amountReturn + parseInt(item.amount, 10);
        });
      exits &&
        exits.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountExit = amountExit + parseInt(item.amount, 10);
        });

      element.amount = amountEntry + amountReturn - amountExit;
    });
    
  useEffect(() => {
    loadEntries();
    loadReturns();
    loadExits();
    loadData();
  }, []);

  return (
    <>
      <Card.Header>
        <h2 className="titleHeader">Inventario de Materiales/Herramientas</h2>
      </Card.Header>
      <Card.Body>
        <MaterialTable
          columns={[
            {
              title: "Codigo",
              field: "code",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Categoria",
              field: "categoryName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre del item",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Referencia",
              field: "reference",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Unidad",
              field: "UnitName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Stock",
              field: "amount",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          isLoading={loading}
          options={options}
          localization={materialTable.localization}
          data={data}
          title="Registros de inventario"
        />
      </Card.Body>
    </>
  );
}
