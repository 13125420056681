import React, { useState, useEffect } from "react";
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import { message, customConfirm } from "../../utils/notification";
import { baseURL } from "../../utils/api/apiStrapi";
import apiStrapi from "../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import "../../css/global.css";

const initialState = {
  code: "",
  statusesId: 1,
  category: 2,
  name: "",
  reference: "",
  numberOfReference: "",
  unitId: "",
  description: "",
  amount: 0,
  value: 0,
  image: null,
};

export default function MaterialsTools() {
  const [form, setForm] = useState({ ...initialState });
  const [file, setFile] = useState({ image: "" });
  const [units, setUnits] = useState();
  const [categories, setCategories] = useState();
  const [list, setList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [entries, setEntries] = useState([]);
  const [exits, setExits] = useState([]);
  const [returns, setReturns] = useState([]);
  const [validated, setValidated] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeFile = (e) => {
    setFile({ image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      const data = new FormData();
      data.append("files.image", file.image);
      data.append("data", JSON.stringify(form));
      if (form.image != null) {
        await apiStrapi.delete(`upload/files/${form.image.id}`);
      }
      try {
        if (isUpdate) {
          const result = await apiStrapi.put(`material-tools/${form.id}`, data);
          if (result) message("Actualizado con exito", 1);
          handleReset();
        } else {
          const validMT = await apiStrapi.get(
            `material-tools?_where[code]=${form.code}`
          );
          if (!validMT.data.length) {
            const result = await apiStrapi.post("material-tools", data);
            if (result) message("Guardado con exito", 1);
            handleReset();
          } else {
            message(`El codigo ${form.code} ya se encuentra registrado`, 2);
          }
        }
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const loadCategories = async () => {
    try {
      const result = await apiStrapi.get("categories?_limit=-1");
      if (result.data) setCategories(result.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadUnits = async () => {
    try {
      const result = await apiStrapi.get("units?_limit=-1");
      if (result.data) setUnits(result.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadExits = async () => {
    try {
      const result = await apiStrapi.get("items-of-exits?_limit=-1");
      const dataExits = await apiStrapi.get("exits?_limit=-1");
      const data = result.data;
      const materialTools = await apiStrapi.get("material-tools");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const exit =
                dataExits.data.length &&
                dataExits.data.filter((e) => e.id == item.exitId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (exit.length > 0 && materiaT.length > 0) {
                const model = {
                  id: exit[0].id,
                  date: exit[0].date,
                  orderOfServiceId: {
                    id: exit[0].orderOfServiceId.id,
                    nameOs: exit[0].orderOfServiceId.nameOs,
                    numberOs: exit[0].orderOfServiceId.numberOs,
                    centerOfCost: exit[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      exit[0].orderOfServiceId.durationOs != undefined
                        ? exit[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(exit[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: exit[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: exit[0].image,
                  MTCode: i.code,
                  clientId: exit[0].orderOfServiceId.clientId,
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        setExits(newData);
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadReturns = async () => {
    try {
      const result = await apiStrapi.get("returns?_limit=-1");
      const data = result.data;
      if (data) setReturns(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadEntries = async () => {
    try {
      const result = await apiStrapi.get("items-of-entries?_limit=-1");
      const data = result.data;
      const dataEmtry = await apiStrapi.get("entries?_limit=-1");
      const materialTools = await apiStrapi.get("material-tools");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const entry =
                dataEmtry.data.length &&
                dataEmtry.data.filter((e) => e.id == item.entrieId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (entry.length > 0 && materiaT.length > 0) {
                const model = {
                  id: entry[0].id,
                  date: entry[0].date,
                  orderOfServiceId: {
                    id: entry[0].orderOfServiceId.id,
                    nameOs: entry[0].orderOfServiceId.nameOs,
                    numberOs: entry[0].orderOfServiceId.numberOs,
                    centerOfCost: entry[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      entry[0].orderOfServiceId.durationOs != undefined
                        ? entry[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(entry[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: entry[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: entry[0].image,
                  MTCode: i.code,
                  clientId: entry[0].orderOfServiceId.clientId,
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        setEntries(newData);
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadStatuses = async () => {
    try {
      const result = await apiStrapi.get("statuses?_limit=-1");
      const data = result.data;
      if (data) setStatuses(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const loadData = async () => {
    try {
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const categoryName = item.category != null ? item.category.name : null;
        const category = item.category != null ? item.category.id : null;
        item.categoryName = categoryName;
        item.category = category;
        const UnitName = item.unitId != null ? item.unitId.name : null;
        const unitId = item.unitId != null ? item.unitId.id : null;
        item.UnitName = UnitName;
        item.unitId = unitId;
        const statusesId = item.statusesId != null ? item.statusesId.id : null;
        const statusesName =
          item.statusesId != null ? item.statusesId.name : null;
        item.statusesId = statusesId;
        item.statusesName = statusesName;
        const position = item.image.length - 1;
        item.image = item.image.length ? item.image[position] : null;
        item.value = item.value;
        item.valueFront = item.value ? formatterPeso.format(item.value) : "";
      });

      if (data) setList(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const deleteRow = async (rowData) => {
    try {
      customConfirm(`Esta seguro que sea eliminar ${rowData.name}`).then(
        async (confirm) => {
          if (confirm) {
            const result = await apiStrapi.delete(
              `material-tools/${rowData.id}`
            );
            if (result.error) {
              message(result.message, 2);
            } else {
              loadData();
              message(`${rowData.name} Eliminado con exito`, 1);
            }
          }
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const newData =
    list.length &&
    list.forEach((element) => {
      let amountEntry = 0;
      let amountReturn = 0;
      let amountExit = 0;
      entries &&
        entries.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountEntry = amountEntry + parseInt(item.amount, 10);
        });
      returns &&
        returns.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountReturn = amountReturn + parseInt(item.amount, 10);
        });
      exits &&
        exits.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountExit = amountExit + parseInt(item.amount, 10);
        });

      element.amount = amountEntry + amountReturn - amountExit;
    });

  const handleStock = (code) => {
    let amountEntry = 0;
    let amountReturn = 0;
    let amountExit = 0;
    if (code) {
      entries &&
        entries.map((item) => {
          if (item.materialToolsId.code == code)
            amountEntry = amountEntry + parseInt(item.amount, 10);
        });
      returns &&
        returns.map((item) => {
          if (item.materialToolsId.code == code)
            amountReturn = amountReturn + parseInt(item.amount, 10);
        });
      exits &&
        exits.map((item) => {
          if (item.materialToolsId.code == code)
            amountExit = amountExit + parseInt(item.amount, 10);
        });
      let amount = amountEntry + amountReturn - amountExit;
      setForm({ ...form, amount });
    }
  };

  const viewImage = (image) => {
    window.open(`${baseURL}${image.slice(1)}`);
  };

  const noPuntoComa = (event) => {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {
      e.preventDefault();
    }
  };

  const handleReset = () => {
    loadData();
    setIsUpdate(false);
    setForm({ ...initialState });
    setValidated(false);
    setFile({ image: "" });
  };

  useEffect(() => {
    loadEntries();
    loadReturns();
    loadExits();
    loadUnits();
    loadCategories();
    loadData();
    loadStatuses();
  }, []);

  useEffect(() => {
    handleStock(form.code);
  }, [form.code]);

  return (
    <Card>
      <Card.Header>
        <h2 className="titleHeader">Creación de materiales y herramientas</h2>
      </Card.Header>
      <Card.Body>
        <Form
          noValidate={true}
          className={validated ? "was-validated" : ""}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Codigo *</p>
                <Form.Control
                  type="text"
                  name="code"
                  value={form.code}
                  onChange={handleChange}
                  placeholder="Codigo"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Nombre del item *</p>
                <Form.Control
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="Nombre del item"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Categoria *</p>
                <Form.Control
                  as="select"
                  name="category"
                  value={form.category}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione...</option>
                  {categories &&
                    categories.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Numero de parte</p>
                <Form.Control
                  type="text"
                  name="reference"
                  value={form.reference}
                  onChange={handleChange}
                  placeholder="Numero de parte"
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Numero de serial</p>
                <Form.Control
                  type="text"
                  name="numberOfReference"
                  value={form.numberOfReference}
                  onChange={handleChange}
                  placeholder="Numero de serial"
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Unidad *</p>
                <Form.Control
                  as="select"
                  name="unitId"
                  value={form.unitId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione...</option>
                  {units &&
                    units.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Cantidad/Stock</p>
                <Form.Control
                  type="text"
                  name="amount"
                  value={form.amount}
                  onChange={handleChange}
                  placeholder="0"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Valor</p>
                <Form.Control
                  type="number"
                  name="value"
                  value={form.value || 0}
                  onChange={handleChange}
                  placeholder="Valor"
                  min={0}
                  onKeyDown={noPuntoComa}
                  required={false}
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Estado *</p>
                <Form.Control
                  as="select"
                  name="statusesId"
                  value={form.statusesId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {statuses &&
                    statuses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <p className="labelStyle">Descripción</p>
                <Form.Control
                  style={{
                    height: "100%",
                    maxHeight: "142px",
                    minHeight: "66px",
                  }}
                  as="textarea"
                  rows={3}
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group controlId="formFile" className="mb-3">
                <p className="labelStyle">Imagen</p>
                <Form.Control
                  onChange={handleChangeFile}
                  name="image"
                  type="file"
                />
              </Form.Group>
              {isUpdate && form.image != null && (
                <div className="d-flex justify-content-around align-items-center">
                  <img
                    onClick={() => {
                      viewImage(form.image.url);
                    }}
                    style={{ cursor: "pointer" }}
                    src={`${baseURL}${form.image.formats.thumbnail.url.slice(
                      1
                    )}`}
                    alt="Ver imagen"
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm="4"></Col>
          </Row>
          <Button variant="primary" type="submit">
            {isUpdate ? "Actualizar" : "Guardar"}
          </Button>
          {isUpdate && (
            <Button variant="danger" onClick={handleReset}>
              Cancelar
            </Button>
          )}
        </Form>

        <MaterialTable
          columns={[
            {
              title: "Codigo",
              field: "code",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Categoria",
              field: "categoryName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre del item",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Numero de parte",
              field: "reference",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Stock",
              field: "amount",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Unidad",
              field: "UnitName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Valor",
              field: "valueFront",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Estado",
              field: "statusesName",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          options={materialTable.options}
          localization={materialTable.localization}
          data={list}
          title="Registro de materiales y herramientas"
          actions={[
            {
              icon: () => <FontAwesomeIcon icon={faPen} />,
              tooltip: "Editar",
              onClick: (event, rowData) => {
                console.log("rowData :>> ", rowData);
                setIsUpdate(true);
                setForm(rowData);
              },
            },
            {
              icon: () => <FontAwesomeIcon icon={faTrash} />,
              tooltip: "Eliminar",
              onClick: (event, rowData) => deleteRow(rowData),
            },
          ]}
        />
      </Card.Body>
    </Card>
  );
}
