import React, { useState, useEffect } from "react";
import apiStrapi from "../utils/api/apiStrapi";
import { Card, Form, Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faClipboard } from "@fortawesome/free-solid-svg-icons";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import materialTable from "../utils/materialTableUtils";
import Autocomplete from "@material-ui/lab/Autocomplete";

const initialItems = {
  materialToolsId: "",
  code: "",
  name: "",
  reference: "",
  amount: 0,
  materialToolsName: "",
};

const columns = [
  {
    title: "Codigo",
    field: "code",
    headerStyle: { fontWeight: "bold" },
  },
  { title: "Nombre", field: "name", headerStyle: { fontWeight: "bold" } },
  {
    title: "Referencia",
    field: "reference",
    headerStyle: { fontWeight: "bold" },
  },
  {
    title: "Cantidad",
    field: "amount",
    type: "numeric",
    headerStyle: { fontWeight: "bold" },
  },
];
const initialInputValue = { id: 1, name: "Seleccione" };

export default function Updateos() {
  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [items, setItems] = useState({ ...initialItems });
  const [inputValue, setInputValue] = useState({ ...initialInputValue });
  const [namePermissions, setNamePermissions] = useState([]);

  const loadNamePermissions = async () => {
    try {
      const result = await apiStrapi.get("name-permissions?_sort=name:ASC");
      result.data ? setNamePermissions(result.data) : setNamePermissions([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadMaterialTools = async () => {
    try {
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      data.forEach((item) => {
        const categoryName = item.category != null ? item.category.name : null;
        const category = item.category != null ? item.category.id : null;
        item.categoryName = categoryName;
        item.category = category;
      });
      if (data) setList(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const addItems = (e) => {
    if (true) {
      e.preventDefault();
      const formControl = e.currentTarget;
      const isValid = formControl.checkValidity() !== false;
      if (items.code == "") {
        console.log(`Debe seleccionar un item`);
      } else if (items.amount !== "") {
        const valit =
          data.length > 0 &&
          data.filter((item) => item.code == items.code && item);
        if (!valit.length > 0) {
          setData([...data, items]);
        } else {
          console.log("Este item ya esta agregado");
        }
      }
    }
    console.log("FIN");
  };

  const loadAutocomplete = async (code) => {
    if (code) {
      try {
        const result =
          list.length && list.filter((item) => item.code == code && item);
        setInputValue(result);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const queryProdutByName = (name) => {
    if (name) {
      list &&
        list.forEach(
          (item) =>
            item.name == name &&
            setItems({
              ...items,
              code: item.code,
              reference: item.reference,
              name: item.name,
            })
        );
    }
  };

  useEffect(() => {
    loadMaterialTools();
    loadNamePermissions()
  }, []);

  useEffect(() => {
    loadAutocomplete(items.code);
  }, [items.code]);

  useEffect(() => {
    queryProdutByName(items.materialToolsName);
  }, [items.materialToolsName]);

  return (
    <>
      <div className="content">
        <Card>
          <div>
            <Row className="justify-content-between">
              <Col sm="3">
                <Form.Group>
                  <p className="labelStyle">Nombre *</p>
                  <Autocomplete
                    options={list}
                    value={inputValue}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, v) => {
                      setItems({
                        ...items,
                        materialToolsName: v.name != null ? v.name : "",
                        materialToolsId: v.id != null ? v.id : "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col sm="4">
              <Form.Group>
                <p className="labelStyle">ROL</p>
                <Form.Control
                  as="select"
                  name="code"
                  value={items.code}
                  onChange={(e) =>
                    setItems({ ...items, code: e.target.value })
                  }
                  required
                >
                  <option value="">Seleccione</option>
                  {namePermissions &&
                    namePermissions.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
              <Col sm="3">
                <Form.Group>
                  <p className="labelStyle">Codigo</p>
                  <Form.Control
                    type="text"
                    name="code"
                    value={items.code}
                    onChange={(e) =>
                      setItems({ ...items, code: e.target.value })
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col sm="3">
                <Form.Group>
                  <p className="labelStyle">Referencia</p>
                  <Form.Control
                    type="text"
                    name="reference"
                    value={items.reference}
                    onChange={(e) =>
                      setItems({
                        ...items,
                        reference: e.target.value,
                      })
                    }
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col sm="3">
                <Form.Group>
                  <p className="labelStyle">Cantidad</p>
                  <Form.Control
                    type="number"
                    name="amount"
                    onChange={(e) =>
                      setItems({ ...items, amount: e.target.value })
                    }
                    value={items.amount}
                    min="1"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Button size="sm" variant="outline-success" onClick={addItems}>
            Agregar item
          </Button>
        </Card>
        <MaterialTable
          columns={columns}
          data={data}
          options={materialTable.options}
          localization={materialTable.localization}
          title=" "
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);

                  resolve();
                }, 1000);
              }),
          }}
          actions={[
            {
              icon: () => <FontAwesomeIcon icon={faPen} />,
              tooltip: "Editar",
              onClick: (event, rowData) => {
                setItems(rowData);
              },
            },
          ]}
        />
      </div>
    </>
  );
}
