import React, { useState, useEffect } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import apiStrapi from "../../utils/api/apiStrapi";
import "../../css/global.css";

const initialState = { orderOfServiceId: "" };

const options = {
  // // headerStyle: { backgroundColor: '#253053', color: 'white' },

  filtering: false,
  grouping: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 1000,
  paging: true,
  pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
  emptyRowsWhenPaging: false,
  search: true,
  sorting: true,
  toolbar: true,
  padding: "dense",
};

export default function ReportStockByOs() {
  const [form, setForm] = useState({ ...initialState });
  const [data, setData] = useState([]);
  const [orderOfServices, setOrderOfServices] = useState([]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const loadExits = async () => {
    try {
      const result = await apiStrapi.get("items-of-exits?_limit=-1");
      const dataExits = await apiStrapi.get("exits?_limit=-1");
      const data = result.data;
      const materialTools = await apiStrapi.get("material-tools?_limit=-1");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const exit =
                dataExits.data.length &&
                dataExits.data.filter((e) => e.id == item.exitId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (exit.length > 0 && materiaT.length > 0) {
                const model = {
                  id: exit[0].id,
                  date: exit[0].date,
                  orderOfServiceId: {
                    id: exit[0].orderOfServiceId.id,
                    nameOs: exit[0].orderOfServiceId.nameOs,
                    numberOs: exit[0].orderOfServiceId.numberOs,
                    centerOfCost: exit[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      exit[0].orderOfServiceId.durationOs != undefined
                        ? exit[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(exit[0].orderOfServiceId.value, 10),
                  },
                  amount: parseInt(i.amount, 10),
                  description: exit[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    name: materiaT[0].name,
                    reference: materiaT[0].reference,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: exit[0].image,
                  MTCode: i.code,
                  clientId: exit[0].orderOfServiceId.clientId,
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadOrderOfServices = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      result.data ? setOrderOfServices(result.data) : setOrderOfServices([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const resultData = async (orderOfServiceId) => {
    if (orderOfServiceId) {
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      if (data) {
        const data_Exits = await loadExits();
        const dataExits = data_Exits.length
          ? data_Exits.filter(
              (item) => item.orderOfServiceId.id == orderOfServiceId && item
            )
          : [];

        const dataReturn = await apiStrapi.get(
          `returns?_where[orderOfServiceId.id]=${orderOfServiceId}`
        );
        let exitsResult = [];
        data.map((item) => {
          let amount = 0;
          const d =
            dataExits &&
            dataExits
              .map((i, index) => {
                if (i.materialToolsId.id == item.id) {
                  amount = amount + parseInt(i.amount, 10);
                  i.amount = amount;
                  return i;
                }
              })
              .filter((item) => item != undefined);
          const position = d.length - 1;
          exitsResult.push(d[position]);
        });

        let returnResult = [];
        data.map((item) => {
          let amount = 0;
          const d =
            dataReturn.data &&
            dataReturn.data
              .map((i) => {
                console.log(`i`, i);
                if (i.materialToolsId.id == item.id) {
                  amount = amount + parseInt(i.amount, 10);
                  i.amount = amount;
                  return i;
                }
              })
              .filter((item) => item != undefined);
          const position = d.length - 1;
          returnResult.push(d[position]);
        });
        const resultExits = exitsResult.filter((item) => item != undefined);
        const resultReturn = returnResult.filter((item) => item != undefined);

        if (resultExits.length) {
          resultExits.forEach((item) => {
            let amount = 0;
            if (resultReturn.length) {
              resultReturn.forEach((i) => {
                if (i.materialToolsId.id == item.materialToolsId.id) {
                  amount = parseInt(item.amount, 10) - parseInt(i.amount, 10);
                  item.amount = amount;
                }
              });
            }
          });
        }

        const dataTable =
          resultExits.length &&
          resultExits.map((item) => {
            const model = {
              orderOfServiceName: item.orderOfServiceId.nameOs,
              code: item.materialToolsId.code,
              nameItem: item.materialToolsId.name,
              reference: item.materialToolsId.reference,
              unitName: "",
              amount: parseInt(item.amount, 10),
            };
            return model;
          });
        setData(dataTable);
      }
    }
  };

  useEffect(() => {
    loadOrderOfServices();
  }, []);

  useEffect(() => {
    resultData(form.orderOfServiceId);
  }, [form.orderOfServiceId]);
  console.log("data", data);
  return (
    <>
      <Card.Header>
        <h2 className="titleHeader">Inventario de Materiales/Herramientas</h2>
      </Card.Header>
      <Card.Body>
        <Form.Group className="sm-4 col-sm-4">
          <p className="labelStyle">Orden de Trabajo *</p>
          <Form.Control
            as="select"
            name="orderOfServiceId"
            value={form.orderOfServiceId}
            onChange={handleChange}
            placeholder="Producto"
            required
          >
            <option value="">Seleccione</option>
            {orderOfServices &&
              orderOfServices.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.nameOs}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <MaterialTable
          columns={[
            {
              title: "Codigo",
              field: "code",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Orden de Trabajo",
              field: "orderOfServiceName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre del item",
              field: "nameItem",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Referencia",
              field: "reference",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Stock",
              field: "amount",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          options={options}
          localization={materialTable.localization}
          data={data.length ? data : []}
          title="Reporte de stock"
        />
      </Card.Body>
    </>
  );
}
